.app {
    font-family: sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    height: 100vh;
    font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
    background-color: #f8f9fd;
  }
  
  input[type="text"],
  input[type="password"] {
    height: 25px;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
  
  /* .logo-container {
    background: #009FDB;
  } */
  .sign-in-button-container {
    margin-top: 10px;
    cursor: pointer;
    font-size: 15px;
    background: #009FDB;
    border: 1px solid #009FDB;
    color: #fff;
    padding: 10px 40%;
    margin-top: 20%;
    margin-left: 4%;
    margin-right: 40%;
  }

  
  button:hover {
    background: #fff;
    color:#009FDB
  }
  
  .sign-in-button-container {
    display: flex;
    justify-content: center;
  }

  
  
  .login-form {
    background-color: white;
    padding: 2rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  
  .list-container {
    display: flex;
  }
  
  .error {
    color: red;
    font-size: 12px;
  }
  
  .title {
    font-size: 25px;
    margin-bottom: 20px;
  }
  
  .input-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 10px;
  }

  .signOut{
      margin-left: 75%;
      font-size: 15px;
      background: #009FDB;
      border: 1px solid #009FDB;
      color: #fff;
  }
  iframe 
  {
   display: block; 
   width: 100%; 
   border: none; 
   overflow-y: auto; 
   overflow-x: hidden;
  }
  html, body {
    height: 100%;
  }
  
  .full-height {
    height: 100%;
  }
.logo{
    height: 200px;
}
.amlogo{
  height:100px;
  padding-bottom: 40px;
}


